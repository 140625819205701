<template>
  <v-row justify="center">
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="80%"
        content-class="bg-white summary-dialog"
        overlay-opacity="0.7"
    >
      <v-row>
        <v-col>
          <v-row>
            <v-spacer></v-spacer>
            <v-icon @click="close" class="ma-1 mt-7 bg-color pa-1" small>mdi-close</v-icon>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-0 mb-12">
        <v-col class="col-sm-5 col-md-6 col-lg-7">
          <h6 class="font-bold ml-10">Checkout</h6>
          <template>
            <v-timeline dense align-top small>
              <v-timeline-item small icon="mdi-checkbox-blank-circle-outline">
                <p class="font-bold">Organization Information</p>
                <v-card class="pb-3">
                  <v-row class="billing-info">
                    <organization-selector class="pt-3" @org-selected="orgSelected" :isFlat=true label="Select Organization"></organization-selector>
                  </v-row>
                  <v-row class="billing-info">
                    <v-col class="pb-0 pt-0">
                      <p class="font-bold mb-0">Contact Information</p>
                      <v-col class="pt-0 pb-0" v-if="organizationId && organizationId !== null && organizationId !== ''">
                        <p class="mb-0">{{form.contact_phone}}</p>
                        <p class="mb-0">{{form.contact_email}}</p>
                      </v-col>
                      <v-col v-else>
                        <p>-</p>
                        <p>-</p>
                        <p>-</p>
                        <p>-</p>
                      </v-col>
                    </v-col>
                    <v-col class="pb-0 pt-0">
                      <p class="font-bold mb-0">Billing Information</p>
                      <v-col class="pt-0 pb-0" v-if="organizationId && organizationId !== null && organizationId !== ''">
                        <p class="mb-0">{{form.address}}</p>
                        <p class="mb-0">{{form.city}} <span v-if="form.city">,</span> {{form.country}}</p>
                      </v-col>
                      <v-col v-else>
                        <p>-</p>
                        <p>-</p>
                        <p>-</p>
                        <p>-</p>
                      </v-col>
                    </v-col>
                  </v-row>
<!--                  <v-col v-if="organizationId && organizationId !== null && organizationId !== ''">-->
<!--                    <p class="font-bold">Contact Person</p>-->
<!--                    <v-text-field label="Contact Person Name" v-model="form.contact_name" readonly></v-text-field>-->
<!--                    <v-text-field label="Phone" v-model="form.contact_phone" readonly></v-text-field>-->
<!--                    <v-text-field label="Email" v-model="form.contact_email" readonly></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col v-if="organizationId && organizationId !== null && organizationId !== ''">-->
<!--                    <p class="font-bold">Company Information</p>-->
<!--                    <v-text-field label="Company Name" v-model="form.name" readonly></v-text-field>-->
<!--                    <v-text-field label="Address" v-model="form.address" readonly></v-text-field>-->
<!--                    <v-text-field label="City" v-model="form.city" readonly></v-text-field>-->
<!--                    <v-text-field label="Country" v-model="form.country" readonly></v-text-field>-->
<!--                  </v-col>-->
                </v-card>
              </v-timeline-item>
              <v-timeline-item small icon="mdi-checkbox-blank-circle-outline" v-if="organizationId && organizationId !== null && organizationId !== ''">
                <p class="font-bold grey--text">Listings</p>
                <v-data-table
                    v-model="selectedItems"
                    :items="filterDraftListings"
                    :headers="headers"
                    item-key="key"
                    show-select
                    checkbox-color="primary"
                    class="elevation-1 mt-2"
                >
                  <template v-slot:item.logoImage="{ item }">
                    <div class="ma-2" v-if="item.logoImage !== null && item.logoImage !== ''">
                      <v-img :src="item.logoImage !== null ? item.logoImage.url : ''" :alt="item.name" height="50px" width="50px" class="border-radius"></v-img>
                    </div>
                    <div class="ma-2 bg-grey border-radius" v-else>

                    </div>
                  </template>
                  <template v-slot:item.type="{ item }">
                    {{capitalizeTheFirstLetterOfEachWord(item.type)}}
                  </template>
                  <template v-slot:item.price="{ item }">
                    <span class="font-bold">€ {{ item.listing_fee }}</span>
                  </template>
                </v-data-table>

              </v-timeline-item>
              <v-timeline-item small icon="mdi-checkbox-blank-circle-outline" v-else>
                <p class="font-bold">Listings</p>
                <v-card>
                  <span class="organization-image pt-12">
                    <v-img class="mt-12" max-height="150" max-width="250" src="@/assets/images/building.png"></v-img>
                  </span>
                  <h6 class="grey--text text-center pb-12">Select an Organization First</h6>
                </v-card>

              </v-timeline-item>
            </v-timeline>
          </template>
        </v-col>
        <v-col class="ml-2 col-sm-6 col-md-5 col-lg-4">
          <v-card class="elevation-1 mt-5">
              <v-col class="ma-1">
                <p class="mb-0">Your Order</p>
                <v-row class="mt-1" v-if="getAuthUser.user_type !== 'admin'">
                  <v-col cols="8" class="pb-0 pt-0">
                    <v-text-field
                        class="mx-4 coupon"
                        v-model="form.coupon_code"
                        :rules="[getRules.required]"
                        :error-messages="getCouponErrors.code"
                        label="Coupon Code"
                        flat
                        :disabled="selectedItems.length === 0 || isCouponApplied === true"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" class="btn-space pb-0 pt-0">
                    <v-btn
                        v-if="isCouponApplied === false"
                        @click="applyCoupon"
                        color="primary"
                        depressed
                        :disabled="selectedItems.length === 0"
                    >Apply</v-btn>
                    <v-btn
                        v-if="isCouponApplied === true"
                        @click="removeCoupon"
                        color="primary"
                        depressed
                    >Remove</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-1 pb-0 pt-0 mb-0 mt-0">
                <v-divider class="dashed"></v-divider>
              </v-col>
            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-col class="ma-2">
                <v-row class="mr-1 ml-1">
                  <p class="grey--text body-2 mb-1">Order Summary</p>
                  <v-spacer></v-spacer>
                </v-row>

                <v-row v-if="getAuthUser.user_type === 'seller'" class="mr-1 ml-1" v-for="(item, index) in form.line_items" :key="index">
                  <p class="grey--text body-2">{{ item.name }}
  <!--                  <span v-if="getCouponLoadStatus === 2 && item.name === 'Listing Fee'"> ({{ getCoupon.name }})</span>-->
                  </p>
                  <v-spacer></v-spacer>
                  <p class="font-bold"> {{item.unit_price * item.quantity | currency}}</p>
                </v-row>

                <v-row v-if="getAuthUser.user_type === 'admin'" class="mr-1 ml-1 my-0 py-0" v-for="(item, customIndex) in form.line_items" :key="'custom'+customIndex">

                  <v-col cols="4" class="py-0 my-0">
                    <v-text-field
                        v-model="form.line_items[customIndex].name"
                        label="Item Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="py-0 my-0">
                    <v-text-field
                        v-model="form.line_items[customIndex].quantity"
                        :rules="[getRules.number,getRules.isInteger]"
                        type="number"
                        min="0"
                        @input="inputChanged"
                        label="Quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="py-0 my-0">
                    <v-text-field
                        v-model="form.line_items[customIndex].unit_price"
                        :rules="[getRules.number]"
                        @input="inputChanged"
                        min="0"
                        label="Unit Price"
                        prefix="€"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="px-0 py-0 my-0">
                    <div class="text--secondary total-label">Total</div>
                    <div class="font-bold total-value">{{ (item.unit_price * item.quantity) | currency }} </div>
                  </v-col>
                  <v-col cols="1" class="px-0 py-0 my-0">
                    <v-btn
                        class="mt-3"
                        icon
                        color="primary"
                        @click="removeInvoiceItem(customIndex)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="getAuthUser.user_type === 'admin'" class="mr-1 ml-1 my-0 py-0">
                  <v-col cols="12" class="py-0 my-0 text-right">
                    <v-btn
                        @click="addInvoiceItem"
                        color="primary"
                        depressed
                        small
                    >Add Item</v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col clas="px-1 py-0 my-0">
                    <v-divider class="dashed"></v-divider>
                  </v-col>
                </v-row>
                <v-row class="mr-1 ml-1">
                  <p class="grey--text body-2 mb-0">Net</p>
                  <v-spacer></v-spacer>
                  <p class="font-bold"> {{( form.net_total ) | currency}}</p>
                </v-row>
                  <v-row class="mx-1 mb-2" align="center">
                  <p class="grey--text body-2 mb-0">
                    Discount
                    <v-tooltip top max-width="200px" class="text-justify">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" small>mdi-information-outline</v-icon>
                      </template>
                      <span>
                        Discount will be applied on Listing Fee & will not be greater than Listing Fee total amount.
                      </span>
                    </v-tooltip>
                  </p>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="pa-0 mr-3" v-if="getAuthUser.user_type === 'admin' && selectedItems.length">
                    <v-text-field
                        dense
                        v-model.number="form.discount"
                        @input="inputChanged"
                        :rules="[validateDiscount, getRules.number]"
                        label="Discount"
                        suffix="€"
                    ></v-text-field>
                  </v-col>

                  <p class="font-bold mb-0" v-else> {{ form.discount | currency}}</p>
                </v-row>

                <v-row class="mr-1 ml-1">
                  <p class="grey--text body-2 mb-0">Subtotal</p>
                  <v-spacer></v-spacer>
                  <p class="font-bold"> {{( form.sub_total ) | currency}}</p>
                </v-row>

                <v-row class="mx-1 mb-2" align="center">
                  <p class="grey--text body-2 mb-0">VAT ({{ form.vat_percentage }}%)</p>
                  <v-spacer></v-spacer>
<!--                  <v-col cols="2" class="pa-0 mr-3" v-if="getAuthUser.user_type === 'admin' && selectedItems.length">-->
<!--                    <v-text-field-->
<!--                        dense-->
<!--                        readonly-->
<!--                        hide-details-->
<!--                        v-model.number="form.vat_percentage"-->
<!--                        @input="inputChanged"-->
<!--                        :rules="[getRules.max, getRules.number]"-->
<!--                        max="100"-->
<!--                        min="0"-->
<!--                        label="Vat"-->
<!--                        suffix="%"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->

                  <p class="font-bold mb-0"> {{ form.vat | currency}}</p>
                </v-row>
                <v-row class="mt-0">
                  <v-col clas="pa-1 pb-0 pt-0 mb-0 mt-0">
                    <v-divider class="dashed"></v-divider>
                  </v-col>
                </v-row>
                <v-row class="mr-1 ml-1 custom-height">
                  <p class="grey--text body-2 mb-0">Total</p>
                  <v-spacer></v-spacer>
                  <p class="font-bold">{{ form.total | currency}}</p>
                </v-row>
              </v-col>
              <v-col class="ma-1 pb-0 pt-0 mb-0 mt-0">
                <v-divider class="dashed"></v-divider>
              </v-col>

              <v-col class="ml-2 my-0 py-0" v-if="getAuthUser.user_type === 'admin'">

                <v-row class="mb-0 mt-3 py-0">
                  <v-col cols="6" class="my-0 py-0">
                    <v-menu
                        v-model="form.period_start_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="form.period_start"
                            label="Subscription Period Start"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="form.period_start"
                          @input="form.period_start_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="my-0 py-0">
                    <v-menu
                        v-model="form.period_end_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="form.period_end"
                            label="Subscription Period End"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="form.period_end"
                          @input="form.period_end_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>


              </v-col>
              <v-col class="ml-2 my-0 py-0" v-if="getAuthUser.user_type === 'admin'">
                <v-checkbox color="primary" class="my-0 py-0" v-model="form.is_paid">
                  <template v-slot:label>
                    <div>Mark as paid</div>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col class="ml-2 my-0 py-0" v-if="getAuthUser.user_type !== 'admin'">
                <v-checkbox color="primary" v-model="terms">
                  <template v-slot:label>
                    <div>I agree to the <a href="#" @click.stop>Terms and Conditions</a></div>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col class="mr-l ml-1 text-center pt-0">
                <!--<v-btn color="accent" depressed class="black&#45;&#45;text activate-button" :disabled="summaryAmount === 0">PAY NOW</v-btn>-->

                <!--Stripe (START)-->
                <div>
                  <stripe-checkout
                      ref="checkoutRef"
                      mode="payment"
                      :pk="publishableKey"
                      :session-id="sessionId"
                      :success-url="successURL"
                      :cancel-url="cancelURL"
                      :customer-email="form.contact_email"
                      @loading="v => loading = v"
                  />
                  <v-btn
                      color="accent"
                      depressed
                      class="black--text activate-button"
                      :disabled="(this.form.total) === 0 || terms === false || !selectedItems.length || !validForm"
                      :loading="loading"
                      @click="payNow"
                  >
                    <span v-if="getAuthUser.user_type !== 'admin'">PAY {{this.form.total | currency}}</span>
                    <span v-else>Create Invoice</span>
                  </v-btn>
                </div>
                <!--Stripe (END)-->

              </v-col>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

    </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import moment from "moment";
import { StripeCheckout } from '@vue-stripe/vue-stripe';
const SelectBox = () => import(/* webpackChunkName: "components" */'@/components/SelectBox');
import Snackbar from "@/components/Snackbar";
export default {
  name: "PaymentSummary",
  props: ['dialog', 'selectedOrganizationId'],
  components: { SelectBox, StripeCheckout, Snackbar },
  data () {
    return {
      // currentDate: new Date().toISOString().substr(0, 10),

      // Stripe (START)
      publishableKey: process.env.VUE_APP_STRIPE_KEY,
      loading: false,
      isCouponApplied: false,
      lineItems: [],
      sessionId: null,
      successURL: process.env.VUE_APP_URL + '/#/hotels?status=success',
      cancelURL: process.env.VUE_APP_URL + '/#/hotels?status=cancelled',
      // Stripe (END)

      snackbarMessage: '',
      headers:[
        {text: 'Thumbnail', value:'logoImage'},
        {text: 'Listing Type', value:'type'},
        {text: 'Name', value: 'name'},
        {text: 'Price/year', value: 'price'},
      ],
      selectedItems: [],
      organization_id:null,
      organizationId: null,
      form: new Form(),
      registrationFee: 0,
      registrationFeePriceId: 'price_1J4s4PIucCMh0e5DDyv3M5I7',
      // method: 'card',
      terms: true,
      validForm: true,
      mountKey: 0,
    };
  },
  mounted() {
    this.initForm();
    this.loadPlans({is_default: 1});
    this.showMessage();
  },
  computed:{
    filterDraftListings(){
      return this.getAllDraftVendorsList(this.organizationId);
    },
    activeOrganization() {
      return this.getRecursiveOrganizationByOrgId(this.organizationId);
    },
    getVendorNames() {
      return _.map(this.selectedItems, 'name');
      },
    ...mapGetters([
      'getOrganizations',
      'getSubscription',
      'getSubscriptionAddStatus',
      'getUser',
      'getAuthUser',
      'getPlans',
      'getCoupon',
      'getRules',
      'getCouponErrors',
      'getCouponLoadStatus',
      'getAllDraftVendorsList',
      'getRecursiveOrganizationByOrgId'
    ]),
  },
  methods: {
    moment,
    orgSelected(orgId) {
      this.organizationId = orgId;
    },
    inputChanged() {
      this.recalculateBill();
    },
    addInvoiceItem() {
      this.form.line_items.push({
        name: '',
        description: '',
        unit_price: 0,
        quantity: 1
      });
    },
    removeInvoiceItem(index) {
      this.form.line_items = _.reject(this.form.line_items, (item, key) => {
        return key === index;
      });
      this.recalculateBill();
    },
    removeCoupon() {
      this.isCouponApplied = false;
      this.ResetCoupon();
      this.form.coupon_code = null;
      this.form.discount = 0;
      this.recalculateBill();
    },
    async applyCoupon() {
      if ( !!this.form.coupon_code ) {
        await this.loadCoupon({mlforceload: true, mlsilent: true, code: this.form.coupon_code.toUpperCase()});
        if ( this.getCouponLoadStatus === 2 ) {
          this.snackbarMessage = "Coupon has been applied successfully.";
          this.$refs.snackbar.type = "success";
          this.$refs.snackbar.show = true;
          this.isCouponApplied = true;
          this.recalculateBill();
        }
        else {
          this.snackbarMessage = "Invalid Coupon Code.";
          this.$refs.snackbar.type = "error";
          this.$refs.snackbar.show = true;
          this.isCouponApplied = false;
        }
      }
    },
    showMessage() {
      let status = this.$route.query.status;
      if ( status === 'success' ) {
        this.snackbarMessage = "Your selected businesses have been activated.";
        this.$refs.snackbar.type = "success";
        this.$refs.snackbar.show = true;
      }
      else if ( status === 'cancelled' ) {
        this.snackbarMessage = "Oops! You've cancelled your order. Please try again!";
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
      }
    },
    async payNow() {
      this.loading = true;
      if (this.$refs.form.validate()) {

        // If: Coupon is set, then set the coupon_id. Else: Remove coupon_id object
        if ( this.getCoupon.id !== null ) { this.form.coupon_id = this.getCoupon.id; }
        else { delete this.form['coupon_id']; }

        let subscription = Object.assign({}, this.form);
        if ( subscription.line_items.length > 0 ) {
          this.setNewSubscription(subscription);
          await this.addSubscription();

          if ( this.getSubscription.session_id !== '' && this.getSubscription.session_id !== null && this.getAuthUser.user_type !== 'admin' ) {
            this.sessionId = this.getSubscription.session_id;
            this.submit();
          }

          if ( this.getAuthUser.user_type === 'admin' ) {
            if ( this.getSubscriptionAddStatus === 2 ) {
              this.snackbarMessage = "Invoice has been generated successfully.";
              this.$refs.snackbar.type = "success";
              this.$refs.snackbar.show = true;
              this.close();
              await this.resetVendorPagination();
              await this.loadVendors();
            }
            else {
              this.snackbarMessage = "Oops! Something went wrong.";
              this.$refs.snackbar.type = "error";
              this.$refs.snackbar.show = true;
            }
          }

        }
        else {
          this.loading = false;
        }
      }
      this.loading = false;
    },
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    close(){
      this.$emit('closePaymentDialog');
      this.initForm();
      this.organization_id = null;
      this.organizationId = null;
      this.mountKey += 1;
    },
    capitalizeTheFirstLetterOfEachWord(words) {
      let separateWord = words.toLowerCase().split(' ');
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
            separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    },
    initForm(){
      if ( this.activeOrganization ) {
        this.form = new Form({
          organization_id: this.activeOrganization.id,
          contact_name: this.activeOrganization.contact_name,
          contact_phone: this.activeOrganization.contact_phone,
          contact_email: this.activeOrganization.billing_email,
          name: this.activeOrganization.name,
          address: this.activeOrganization.address,
          city: this.activeOrganization.city,
          country: this.activeOrganization.country,
          is_paid: true,
          line_items: [],
          period_start: moment().format('YYYY-MM-DD'),
          period_start_menu: false,
          period_end: moment().add(1, 'years').format('YYYY-MM-DD'),
          period_end_menu: false,
          net_total: 0,
          discount: 0,
          sub_total: 0,
          vat_percentage: 0,
          vat: 0,
          total: 0,
        });
      }

      if (this.getUser){
        this.organization_id = this.getUser.organization_id;
      }
    },
    setStripeLineItems(vendors=[]) {
      let lineItems = [];
      let unregisteredItems = _.filter(vendors, {is_registered: 0});

      // Set Stripe Registration Fee
      if ( unregisteredItems.length > 0 ) {
        lineItems.push({price: this.registrationFeePriceId, quantity: unregisteredItems.length});
      }

      // Set Listing Price
      if ( vendors.length > 0 ) {
        lineItems.push({price: 'price_1J6FohIucCMh0e5DfBGCI86F', quantity: vendors.length});
      }
      this.lineItems = lineItems;
    },
    recalculateBill() {
      let hotelIds = _.without(_.map(this.selectedItems, (vendor) => { if ( vendor.type === 'hotel' ) { return vendor.id; } }), undefined);
      let locationIds = _.without(_.map(this.selectedItems, (vendor) => { if ( vendor.type === 'location' ) { return vendor.id; } }), undefined);
      if ( hotelIds.length > 0 ) { this.form.hotels = hotelIds; }
      if ( locationIds.length > 0 ) { this.form.locations = locationIds; }

      // Registration & Listing Fee (START)
      let listingFee = 120;
      let listingFeePlan = _.find(this.getPlans, {type: 'listing_fee'});
      if ( listingFeePlan ) { listingFee = listingFeePlan.unit_price; }
      let registrationFee = 69;
      let registrationFeePlan = _.find(this.getPlans, {type: 'registration_fee'});
      if ( registrationFeePlan ) { registrationFee = registrationFeePlan.unit_price; }
      // Registration & Listing Fee (END)
      // Add Registration Fee Line Item
      if ( this.getAuthUser.user_type === 'admin' ) {
        let isRegistrationFeeAdded = _.some(this.form.line_items, {name: 'Registration Fee'});
        if ( isRegistrationFeeAdded === false && this.selectedItems.length > 0 ) {
          this.form.line_items.push({
            name: 'Registration Fee',
            description: 'One time registration fee',
            unit_price: registrationFee,
            quantity: hotelIds.length + locationIds.length,
          });
        }

        // Add Listing Fee Line Item
        let isListingFeeAdded = _.some(this.form.line_items, {name: 'Listing Fee'});
        if ( isListingFeeAdded === false && this.selectedItems.length > 0 ) {
          this.form.line_items.push({
            name: 'Listing Fee',
            //description: (this.isCouponApplied === true ? '('+this.getCoupon.discount+'% discount)' : vendorNames.join(', ')),
            description: this.getVendorNames.join(', '),
            //unit_price: listingFee - (this.isCouponApplied === true ? (listingFee / 100 * this.getCoupon.discount) : 0),
            unit_price: listingFee,
            quantity: hotelIds.length + locationIds.length,
          });
        }
      }
      else {
        this.form.line_items = [
          {
            name: 'Registration Fee',
            description: 'One time registration fee',
            unit_price: registrationFee,
            quantity: hotelIds.length + locationIds.length,
          },
          {
            name: 'Listing Fee',
            //description: (this.isCouponApplied === true ? '('+this.getCoupon.discount+'% discount)' : vendorNames.join(', ')),
            description: this.getVendorNames.join(', '),
            //unit_price: listingFee - (this.isCouponApplied === true ? (listingFee / 100 * this.getCoupon.discount) : 0),
            unit_price: listingFee,
            quantity: hotelIds.length + locationIds.length,
          }
        ];
      }


      this.form.net_total = 0;
      _.forEach(this.form.line_items, (lineItem) => {
        this.form.net_total += lineItem.quantity * lineItem.unit_price;
      });

      let discount = 0;
      if ( this.isCouponApplied === true && this.getAuthUser.user_type === 'seller' ) {
        if ( this.form.line_items.length > 0 ) {
          let item = _.find(this.form.line_items, {name: 'Listing Fee'});
          if ( item ) { discount = (item.unit_price * item.quantity) / 100 * this.getCoupon.discount;
          }
        }
        this.form.discount = discount;
      }


      //this.form.discount = this.isCouponApplied === true ? (this.form.net_total / 100 * this.getCoupon.discount) : 0;

      // CALCULATE VAT FOR GERMANY
      if(this.form.vat_percentage === 0 && this.form.country === 'Germany') {
        this.form.vat_percentage = 19;
      }

    },
    validateDiscount(discount){
      let listingFeeItem = _.find(this.form.line_items, {name: 'Listing Fee'});
      if ( listingFeeItem ) {
        if ( discount > listingFeeItem.unit_price * listingFeeItem.quantity ) {
          return "Exceeds limit";
        }
      }
      return true;
    },
    ...mapActions(['loadPlans', 'addSubscription', 'loadCoupons', 'loadCoupon','loadVendors']),
    ...mapMutations(['setNewSubscription', 'ResetCoupon','resetVendorPagination']),
  },
  watch: {
    organizationId() {
      this.initForm();
      this.selectedItems = [];
    },
    selectedItems(){
      if(this.form.line_items && this.form.line_items[0]){
        this.form.line_items[0].quantity = this.selectedItems.length;
        this.form.line_items[1].quantity = this.selectedItems.length;
      }
      if(!this.selectedItems.length){
        this.form.line_items = [];
      }
      this.recalculateBill();
    },
    'form.period_start': function(periodStart) {
      this.form.period_end = moment(periodStart).add(1, 'years').format('YYYY-MM-DD');
    },
    'form.sub_total' : function(val) {
      this.form.vat = this.form.sub_total * (this.form.vat_percentage / 100);
      this.form.total = this.form.sub_total + this.form.vat;
    },
    'form.net_total' : function(net_total) {
      this.form.sub_total = this.form.net_total - this.form.discount;
    },

    'form.discount' : function(discount) {
      this.form.discount = (this.form.discount < 0) ? this.form.discount * -1 : this.form.discount;
      this.form.sub_total = this.form.net_total - this.form.discount;
    },

    'form.vat_percentage' : function(vat) {
      this.form.vat = this.form.sub_total * (vat / 100);
      this.form.total = this.form.sub_total + this.form.vat;

    },

  }
}
</script>
<style scoped>
.border-radius{
  border-radius: 10px !important;
}
.coupon{
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 0 !important;
}
.dashed{
  border: 1px dashed;
  opacity: 0.5;
}
.activate-button{
  width:95% !important;
}
.btn-space{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.bg-grey{
  background-color: grey;
  height: 50px;
  width: 50px;

}
.bg-color{
  background-color: #EEEEEE;
  border-radius: 20px;
}
.cursor-pointer{
  cursor: pointer;
}
.billing-info{
  max-width:90%;
  margin: 0 auto;
}
.select-dropdown{
  width:100%;
}
.organization-image{
  justify-content: center;
  display: grid;
}
.custom-height{
  height: 20px !important;
}
</style>
<style>
.bg-white{
  background-color: white !important;
}
.summary-dialog{
  min-height: 80%;
}
.v-timeline {
  margin-top: 24px !important;
  padding-top: 0 !important;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  border: 1px dashed !important;
  opacity: 0.5 !important;
}
.total-label {
  font-size: 10px;
  margin-top: 5px;
}
.total-value {
  font-size: 12px;
  margin-top: 5px;
}
</style>